export default function initTeams() {
	const teams = document.querySelector('#teams');
	if (!teams) return;

	const items = teams.querySelectorAll('.team-item');
	const gap = 60;
	const teamsWrapper = teams.querySelector('#teams-wrapper');
	const teamDescriptionWrapper = document.querySelector('.team-description-wrapper');
	let activeItem = null;
	let width = 0;
	let minWidth = 0;

	function resetActive() {
		if(!activeItem) return;

		activeItem.classList.remove('active');
    activeItem.setAttribute('aria-expanded', 'false');

		// remove inline styles
		const img = activeItem.querySelector('.img-wrapper');
		img.style.minWidth = `${minWidth}px`;
		img.style.width = `${minWidth}px`;
	}

	function showDescription(id) {
		const descriptions = teams.querySelectorAll('.team-description');
		descriptions.forEach((description) => {
			description.classList.remove('active');
      description.setAttribute('aria-hidden', 'true');
		});
		const description = teams.querySelector('.team-description[data-item-id="'+id+'"]');
		description.classList.add('active');
    description.setAttribute('aria-hidden', 'false');
	}

	function init() {
		const wrapperWidth = teamsWrapper.clientWidth-gap;

		width = (wrapperWidth/4)*1.2;
		minWidth = (wrapperWidth-width)/3;

		items.forEach((item, index) => {

			const img = item.querySelector('.img-wrapper');

			// if(index === 0) {
			// 	// width = img.clientWidth;
			// 	// item.classList.add('active');
			// 	img.style.minWidth = `${width}px`;
			// 	img.style.width = `${width}px`;
			// 	activeItem = item;
			// 	const id = item.dataset.itemId;
			// 	showDescription(id);
			// } else {
				// minWidth = img.clientWidth;
      img.style.minWidth = `${minWidth}px`;
      img.style.width = `${minWidth}px`;
			// }

			item.addEventListener('click', () => {
				resetActive();
				const id = item.dataset.itemId;
				showDescription(id);
				item.classList.add('active');
        item.setAttribute('aria-expanded', 'true');
				teamDescriptionWrapper.classList.add('active');
				img.style.minWidth = `${width}px`;
				img.style.width = `${width}px`;
				activeItem = item;

				// scroll to active item
				const elementPosition = item.getBoundingClientRect().top;
				const offsetPosition = elementPosition - 50;
				window.scrollBy({
					top: offsetPosition,
					behavior: "smooth",
				});

			});
		});
	}

	window.addEventListener('resize', () => {
		items.forEach((item) => {
			const img = item.querySelector('.img-wrapper');
			img.style.minWidth = '';
			img.style.width = '';
		});

		init();
	});

	// trigger resize event to init
	window.dispatchEvent(new Event('resize'));
}
